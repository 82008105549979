import React from "react";
import { Link } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { usePosts } from "../../hooks/usePosts";

import "./styles.css"
import { normalizeString } from "../../utils/normalizeString";


interface PostProps {
    keyword?: string;
}

const LatestPosts: React.FC<PostProps> = ({ keyword }) => {
    const { data: lastPosts = [], isLoading } = usePosts({ per_page: 100 });


    // Filtro de posts com base na keyword
    const filteredPosts = keyword
        ? lastPosts.filter((post) => {
            const normalizedKeyword = normalizeString(keyword);
            const normalizedTags = normalizeString(post.tags);

            // Dividindo a palavra-chave em várias palavras e verificando se todas aparecem no título ou resumo
            return normalizedKeyword
                .split(" ")
                .every((word) =>
                    normalizedTags.includes(word)
                );
        })
        : lastPosts;

    return (
        <div className={filteredPosts.length > 0 ? "container my-5" : "d-none"}>

            {!isLoading && filteredPosts.length > 0 && (
                <>
                    <h2 className="text-center fw-bold mb-4 fs-4 fs-md-3 fs-lg-2 fs-xl-1 text-title">Artigos relacionados</h2>
                    <hr />

                    <Swiper
                        className="relatedPosts"
                        modules={[Navigation]}
                        spaceBetween={20}
                        slidesPerView={1}
                        navigation
                        breakpoints={{
                            768: { slidesPerView: 2 },
                            1024: { slidesPerView: 2 },
                        }}
                    >
                        {filteredPosts.map((post) => (
                            <SwiperSlide key={post.slug}>
                                <div className="post-card">
                                    <img
                                        src={post.capa_da_postagem?.guid || "/images/placeholder.jpg"}
                                        alt={post.titulo_da_postagem}
                                        loading="lazy"
                                    />
                                    <div className="post-content">
                                        <span className={`badge ${post.categoria.toLowerCase()}`}>
                                            {post.categoria}
                                        </span>
                                        <h3>{post.titulo_da_postagem}</h3>
                                        <p>{post.resumo}</p>
                                        <Link
                                            to={`/blog/${post.slug}`}
                                            className="btn-see-more"
                                            aria-label={`Leia mais sobre ${post.titulo_da_postagem}`}
                                        >
                                            Ler mais
                                        </Link>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </>
            )}
        </div>
    );
};

export default LatestPosts;
