import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { Link } from "gatsby";
import { useReceipts } from "../../hooks/useReceipts";
import "swiper/css";
import "swiper/css/navigation";
import "./styles.css";
import { Icon } from "@chakra-ui/react";
import { normalizeString } from "../../utils/normalizeString";

interface RelatedReceiptsProps {
    keyword?: string; // ex: "doce", "salgado", etc
}

const RelatedReceipts: React.FC<RelatedReceiptsProps> = ({ keyword }) => {
    const { data: receipts = [], isLoading } = useReceipts(1, {});

    const filteredReceipts = keyword
        ? receipts.filter((receipt) => {
            const normalizedKeyword = normalizeString(keyword);
            const normalizedTag = normalizeString(receipt.ingredientes || "");
            return normalizedTag.includes(normalizedKeyword);
        })
        : receipts;

    return (
        <div className={filteredReceipts.length > 0 ?  "container my-5" : "d-none"}>
            {!isLoading && filteredReceipts.length > 0 && (
                <>
                    <h2 className="text-center fw-bold mb-4 fs-4 fs-md-3 fs-lg-2 fs-xl-1 text-title">
                        Receitas relacionadas
                    </h2>
                    <hr />

                    <Swiper
                        modules={[Navigation]}
                        className="relatedReceipts"
                        spaceBetween={20}
                        slidesPerView={1}
                        navigation
                        breakpoints={{
                            768: { slidesPerView: 3 },
                            1024: { slidesPerView: 3.5 },
                            1280: { slidesPerView: 4 },
                        }}
                    >
                        {filteredReceipts.map((receipt) => (
                            <SwiperSlide key={receipt.id}>
                                <Link
                                    to={`/receitas/${receipt.slug}`} className="receipt-card">
                                    <img
                                        src={receipt.imagem_de_capa?.guid || "/images/placeholder.jpg"}
                                        alt={receipt.title.rendered}
                                        loading="lazy"
                                    />
                                    <div className="receipt-content">
                                        <h3>{receipt.title.rendered}</h3>
                                        <p>
                                            <Icon>
                                                <svg className="icon-clock-receipt" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                </svg>
                                            </Icon> {receipt.tempo_de_preparo} min</p>
                                    </div>




                                </Link>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </>
            )}
        </div>
    );
};

export default RelatedReceipts;
